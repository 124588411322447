/**
 * This function receives all the information to show the modals in Home
 * @param {boolean} isCitaFlow
 * @param {string} priceCheck
 * @param {string} buttonUrl
 * @param {string} publicKey
 * @param {string} redirectUrl
 * @param {string} timer
 * @param {object} addressToModal
 * @param {string} backCheckout
 * @param {function} setCartOrdered
 * @returns {object} Data information for AlertModal
 */

function alertModalInfo(
  isCitaFlow,
  priceCheck,
  buttonUrl,
  publicKey,
  redirectUrl,
  timer,
  addressToModal,
  backCheckout,
  setCartOrdered,
) {
  const publicKeyLink = publicKey ? publicKey.split('/')[3].split('?')[0] : '';
  const dataModal = {
    dataPreventModalPaid: {
      title: 'Hemos recibido tu pago',
      description:
        'El carrito al que estás intentando acceder ya fue pagado de forma exitosa.',
      button: isCitaFlow ? 'Ir a mi cuenta' : 'Regresar a la tienda',
      discard: 'Regresar a moons.mx',
      imgAlert: priceCheck,
      urlRedirect: isCitaFlow
        ? `${buttonUrl}&token=${publicKeyLink}54${publicKeyLink}`
        : redirectUrl,
      discardLink: 'https://mymoons.mx',
    },
    dataPreventModalPending: {
      title: 'Pago pendiente',
      description:
        'Este carrito tiene un pago pendiente. Complétalo a través del método de pago que escogiste o cámbialo por algún otro.',
      button: 'Mantener mi método de pago',
      discard: 'Cambiar método de pago',
      imgAlert: timer,
      urlRedirect: isCitaFlow ? 'https://mymoons.mx' : redirectUrl,
      address: addressToModal,
    },
    outOfStockModal: {
      title: '¡Lo sentimos!',
      description:
        'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
      button: 'Regresar a la tienda',
      imgAlert: backCheckout,
      urlRedirect: redirectUrl,
      discardLink: redirectUrl,
    },
    timeOutCitaFlowModal: {
      title: 'Tiempo agotado',
      description:
        'Debido al alto volumen de solicitudes, no podemos apartar tu seleccíon por mucho tiempo. Es necesario que selecciones nuevamente la fecha y la hora de tu cita.',
      button: 'Selecciona fecha y hora',
      imgAlert: timer,
      urlRedirect: redirectUrl,
    },
  };

  const modalStrategies = {
    paid: {
      data: dataModal.dataPreventModalPaid,
      modal: false,
      discardOption: true,
    },
    pending: {
      data: dataModal.dataPreventModalPending,
      modal: setCartOrdered,
      discardOption: false,
    },
    outOfStock: {
      data: dataModal.outOfStockModal,
      modal: false,
      discardOption: false,
    },
    timeOut: {
      data: dataModal.timeOutCitaFlowModal,
      modal: false,
      discardOption: false,
    },
  };

  return modalStrategies;
}

export default alertModalInfo;
