/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@mymoons/ui-library';
import {
  priceCheck,
} from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import ThankYouContext from '../../../../../../context/ThankYou/Context';
import ProductContext from '../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../context/Global/Context';
import PaymentContext from '../../../../../../context/Payment/Context';
import axiosOrder from '../../../../../../methods/cashPayments/mx/axiosOrder';
import sendFreshpaintEvent from '../../../../../../utils/sendFreshpaintEvent';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import AlertModal from '../../../../../AlertModal';
import { fetchSocialByCountry } from '../../../../../../utils/locale';

const FlowCloseOrder = () => {
  const productContext = useContext(ProductContext);
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const globalContext = useContext(GlobalContext);
  const [loadingCardButton, setLoadingCardButton] = useState(false);
  const [preventModal, setPreventModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const closeOrderData = organizeDataPayments();
  const { setDataClose } = thankYouContext;

  const {
    productsWithDiscount,
    carts: cartsProducts,
  } = productContext;
  const {
    isCitaFlow,
    isOnlyComplementaryPayment,
    isOnlyAdvance,
    name,
    email,
    customerId,
    country,
    publicKey,
    origin,
    redirectUrl,
    setEmptyAddressFunction,
    appointmentRedirect,
    buttonUrl: buttonToDashboard,
  } = globalContext;

  const {
    hasAddress,
  } = paymentContext;

  const { code, number, whatsAppUrl } = fetchSocialByCountry(
    country,
    'whatsapp'
  );

  const carts = new URLSearchParams(window.location.search).getAll('cart');

  const dataToFreshpaint = {
    customer_id: customerId,
    distinct_id: customerId,
    product_id: cartsProducts[0]?.lineItems[0]?.id,
    cart_id: cartsProducts[0]?.id,
    product_quantity: cartsProducts[0]?.lineItems.length,
    public_key: publicKey,
    country_ops: country,
    currency: 'MXN',
    payment_method: 'oxxo',
    payment_gateway: 'spei',
    product_category: 'appointment',
    product: 'appointment-mx',
    payment_type: 'Complete',
    platform: 'checkout_v3',
    transaction_amount: cartsProducts[0]?.totalPrice.centAmount / 100,
    name,
    email,
  };

  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: redirectUrl,
    discardLink: redirectUrl,
  };

  const paymentErrorModal = {
    title: '¡Ocurrió un error!',
    description:
      'Ocurrió un error con el método de pago, por favor intenta con otro método de pago.',
    button: 'Cambiar método de pago',
    imgAlert: priceCheck,
    urlRedirect:document.URL,
  };

  React.useEffect(() => {
    const event = new Event('DOMContentLoaded');
    document.dispatchEvent(event);
  }, []);

  const handleCreateOrder = async (e) => {
    try{
      e.preventDefault();
      e.stopPropagation();
      
      if (country !== 'MX' || (!isCitaFlow && !isOnlyAdvance && !isOnlyComplementaryPayment)) {
        if (!hasAddress) {
          setEmptyAddressFunction(true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return;
        }
      }

      const { data: upToDateCartData } = await axiosPutRecalculateCarts(carts);
      if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
        setPreventModal(true);
      } else {
        setPreventModal(false);
      }

      setLoadingCardButton(true)
      const {cartData, payer, salesAgentId, nameAgent} = closeOrderData
      await axiosOrder({
        'salesAgentId': salesAgentId ? salesAgentId : '',
        'nameAgent': nameAgent ? nameAgent : '',
        payer,
        cartData:{
          id: cartData?.id,
          productCategory: cartData?.productCategory,
          productKey: cartData?.productKey
        }
      })
      setDataClose({transactionId:cartsProducts[0]?.id})
      setLoadingCardButton(false)
      window.location.replace(`${window.location.origin}/payment/order`);
    }catch(err){
      console.log(err)
    }
  };

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      {errorModal && (
        <AlertModal
          data={paymentErrorModal}
          modal={false}
          discardOption={false}
        />
      )}
      <div className={styles.button}>
        <form onSubmit={handleCreateOrder} className={styles.form}>
          <Button
            id="Check_Flow_Card"
            color="red"
            fullWidth
            isButton
            label={`Completar Orden`}
            rel="noopener noreferrer"
            size="medium"
            variant="filled"
            type="submit"
            loading={loadingCardButton}
          />
        </form>
      </div>
    </>
  );
};

export default FlowCloseOrder;
