import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import ThankYouContext from '../Context';
import ThankYouReducer from '../Reducer';
import {
  USER_INFO,
  DATA_CARD,
  DATA_EFECTY,
  DATA_OXXO,
  DATA_SPEI,
  DATA_PAYPAL,
  DATA_PSE,
  DATA_EFECTIVO,
  DATA_CLOSE,
} from '../types';

const ThankYouState = ({ children }) => {
  const initialstate = {
    user: {},
    dataOxxo: localStorage.getItem('dataOxxo'),
    dataEfecty: localStorage.getItem('dataEfecty'),
    dataEfectivo: localStorage.getItem('dataEfectivo'),
    dataSpei: localStorage.getItem('dataSpei'),
    dataCard: localStorage.getItem('dataCard'),
    dataPayPal: localStorage.getItem('dataPayPal'),
    dataPSE: localStorage.getItem('dataPSE'),
    dataClose: localStorage.getItem('dataClose'),
  };

  const [state, dispatch] = useReducer(ThankYouReducer, initialstate);

  const updateUserInformation = (data) => {
    dispatch({
      type: USER_INFO,
      payload: data,
    });
  };

  const setDataOxxo = (data) => {
    dispatch({
      type: DATA_OXXO,
      payload: localStorage.setItem('dataOxxo', JSON.stringify(data)),
    });
  };

  const setDataEfecty = (data) => {
    dispatch({
      type: DATA_EFECTY,
      payload: localStorage.setItem('dataEfecty', JSON.stringify(data)),
    });
  };

  const setDataEfectivo = (data) => {
    dispatch({
      type: DATA_EFECTIVO,
      payload: localStorage.setItem('dataEfecty', JSON.stringify(data)),
    });
  };

  const setDataSpei = (data) => {
    dispatch({
      type: DATA_SPEI,
      payload: localStorage.setItem('dataSpei', JSON.stringify(data)),
    });
  };

  const setDataCard = (data) => {
    dispatch({
      type: DATA_CARD,
      payload: localStorage.setItem('dataCard', JSON.stringify(data)),
    });
  };

  const setDataClose = (data) => {
    dispatch({
      type: DATA_CLOSE,
      payload: localStorage.setItem('dataClose', JSON.stringify(data)),
    });
  };

  const setDataPayPal = (data) => {
    dispatch({
      type: DATA_PAYPAL,
      payload: localStorage.setItem('dataPayPal', JSON.stringify(data)),
    });
  };

  const setDataPSE = (data) => {
    dispatch({
      type: DATA_PSE,
      payload: localStorage.setItem('dataPSE', JSON.stringify(data)),
    });
  };

  const contextValue = useMemo(() => {
    return {
      user: state.user,
      dataOxxo: state.dataOxxo,
      dataSpei: state.dataSpei,
      dataCard: state.dataCard,
      dataPayPal: state.dataPayPal,
      dataPSE: state.dataPSE,
      dataClose: state.dataClose,
      updateUserInformation,
      setDataOxxo,
      setDataEfectivo,
      setDataEfecty,
      setDataSpei,
      setDataCard,
      setDataPayPal,
      setDataPSE,
      setDataClose,
    };
  }, [
    state.dataPSE,
    state.user,
    state.dataOxxo,
    state.dataSpei,
    state.dataCard,
    state.dataPayPal,
    state.dataClose,
    updateUserInformation,
    setDataOxxo,
    setDataEfecty,
    setDataEfectivo,
    setDataCard,
    setDataPayPal,
    setDataPSE,
    setDataClose,
  ]);

  return (
    <ThankYouContext.Provider value={contextValue}>
      {children}
    </ThankYouContext.Provider>
  );
};

export default ThankYouState;

ThankYouState.propTypes = {
  children: PropTypes.node.isRequired,
};
